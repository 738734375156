import { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography, Box, Container, CircularProgress } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { ShimmerTable } from 'react-shimmer-effects';
import InfiniteScroll from 'react-infinite-scroll-component';
import { toast } from 'react-toastify';
//
import { SUPPORT_TICKET_PAGINATION_LIMIT } from 'features/base/constants/pagination';
import useIsInitialize from 'features/base/hooks/use-is-initialize';
import { selectProject } from 'features/projects/selectors';
import {
  selectSupportTickets,
  selectLoader,
  selectTicketStatusLoader,
  selectUpdatedSupportTicket,
} from 'features/support-tickets/selectors';
import { supportTicketActions } from 'features/support-tickets/slice';
import ConfirmationPopup from 'features/base/components/confirmation-popup';
import { Select as SelectBox, DataGrid, Button } from 'features/base/components';
import { SUPPORT_TICKET_SORTBY_FIELDS } from 'features/base/constants/support-ticket-sortby-fields';
import ROUTES from 'features/base/constants/routes';
import { selectUserId } from 'features/base/auth/selectors';
import { selectNotification } from 'features/base/notifications/selectors';
import useIsPermissionsVerified from 'features/base/hooks/use-permission-verifier';
import TOAST_TYPES from 'features/base/constants/toast-types';
import { PERMISSION_ACTIONS, PERMISSION_DOMAINS } from 'features/base/constants/permissions';
import { SUPPORT_TICKET_STATUS } from 'features/base/constants/support-ticket-status-types';
import { SORT_TYPES } from 'features/base/constants/sort-types';
import ERROR_TYPES from 'features/base/constants/error-types';
import { capitalizeFirstLetter, toUpperCase } from 'features/base/helpers/strings';
import { DATE_LANGUAGE, DATE_FORMAT_SLASH } from 'features/base/constants/date-formatting';
import { SEVERITY_TYPES } from 'features/base/constants/severity-types';
import { SUPPORT_TICKET_HANDLING_TABLE_COLUMNS } from 'features/base/utils/tables';
import { SUPPORT_TICKET_FILTER_TYPES } from 'features/base/constants/support-ticket-filter-types';
import AttachSupportTicketsPopup from './attach-support-ticket';
import CommentSectionPopup from '../../../support-tickets/components/comment-section-modal';
/**
 * Table component to render the handling support tickets of a project
 * @returns {React.Component}
 */
const HandlingSupportTicketsTable = () => {
  const { permissionsVerified } = useIsPermissionsVerified();
  //
  // eslint-disable-next-line no-unused-vars
  const supportTicketsViewPermissionsVerified = permissionsVerified([
    {
      domain: PERMISSION_DOMAINS.TICKET_HANDLING,
      action: PERMISSION_ACTIONS.READ,
    },
  ]);
  //
  const dispatch = useDispatch();
  const { projectId } = useParams();
  //
  const projectDetails = useSelector(selectProject);

  const notification = useSelector(selectNotification);
  const userId = useSelector(selectUserId);
  const supportTickets = useSelector(selectSupportTickets);
  const isLoading = useSelector(selectLoader);
  const ticketStatusLoading = useSelector(selectTicketStatusLoader);
  const updatedSupportTicket = useSelector(selectUpdatedSupportTicket);
  //
  const isFirst = useIsInitialize(supportTickets);
  //
  const [type] = useState(SUPPORT_TICKET_FILTER_TYPES.HANDLING);
  const [severityValue, setSeverity] = useState('All');
  const [statusValue, setStatus] = useState('All');
  const [pageController, setPageController] = useState({
    sortBy: SORT_TYPES.DESC,
    sortByField: 'updatedAt',
    rowsPerPage: SUPPORT_TICKET_PAGINATION_LIMIT,
  });
  const [isResolvePopupOpen, setIsResolvePopupOpen] = useState(false);
  const [ticketToResolve, setTicketToResolve] = useState(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [commentSectionModelOpen, setCommentSectionModelOpen] = useState(false);
  const [ticketId, setTicketId] = useState('');
  const [page, setPage] = useState(1);
  //
  const params = useMemo(
    () => ({
      userId,
      type,
      severity: severityValue,
      projectId,
      status: statusValue,
      limit: pageController.rowsPerPage,
      page,
      sortBy: pageController.sortBy,
      sortByField: pageController.sortByField,
    }),
    [severityValue, statusValue, projectId, type, pageController, page]
  );
  //
  const handleOnConfirmationSubmit = () => {
    dispatch(
      supportTicketActions.updateResolvedStatus({
        id: ticketToResolve?.id,
        acknowledged: {
          isAcknowledged: true,
        },
      })
    );
  };
  //
  const fetchNextPage = () => {
    setPage(supportTickets?.page ? supportTickets.page + 1 : 0);
  };
  //
  const initialize = () => {
    setPage(1);
    dispatch(supportTicketActions.resetSupportTickets());
  };
  //
  useEffect(() => {
    dispatch(
      supportTicketActions.getItSupportProjects({ query: `itSupport=${true}&pagination=false` })
    );
    dispatch(
      supportTicketActions.getManagingProjects({
        query: `ticketAssignees=${userId}&pagination=${false}`,
      })
    );
  }, []);
  //
  useEffect(() => {
    if (notification?.isEnabled && notification?.type === ERROR_TYPES.SUCCESS) {
      toast(notification?.message, { type: TOAST_TYPES.SUCCESS });
      if (isResolvePopupOpen) {
        setIsResolvePopupOpen(false);
      }
    }
  }, [notification]);
  //
  useEffect(() => {
    let formattedParamString = Object.entries(params)
      .filter(([, paramValue]) => paramValue && paramValue !== 'All' && paramValue !== '')
      .filter(([paramKey]) => paramKey !== 'sortByField' && paramKey !== 'sortBy')
      .map(([paramKey, paramValue]) => `${paramKey}=${paramValue}`)
      .join('&');
    formattedParamString += `&sortBy=${pageController.sortByField}:${pageController.sortBy}`;
    //
    dispatch(supportTicketActions.getSupportTickets({ query: formattedParamString }));
  }, [params, updatedSupportTicket]);
  //
  useEffect(
    () => () => {
      dispatch(supportTicketActions.resetSupportTickets());
    },
    []
  );
  //
  const handleTitleOnClick = (event, id) => {
    window.open(ROUTES.SINGLE_SUPPORT_TICKET.replace(':id', id), '_blank');
  };
  //
  const handleCommentOnClick = (selectedTicketId) => {
    dispatch(supportTicketActions.setIsInitialCommentFetching(true));
    dispatch(supportTicketActions.getComments({ id: selectedTicketId }));
    setCommentSectionModelOpen(true);
    setTicketId(selectedTicketId);
  };
  //
  return (
    <Box>
      <Container maxWidth="xl" sx={{ height: 'fit-content', mt: 2, mb: 2 }} px={{ xs: 0, lg: 2 }}>
        <ConfirmationPopup
          open={isResolvePopupOpen}
          displayMessage="Do you really want to Resolve this item?"
          handleOnClose={() => setIsResolvePopupOpen(false)}
          handleOnConfirmation={handleOnConfirmationSubmit}
          loading={ticketStatusLoading}
        />
        <Grid container direction="row" alignItems="center" justifyContent="space-between" mb={3}>
          <Typography variant="h5" sx={{ fontWeight: 'bold', mb: { xs: '10px', sm: 0 } }}>
            {`Handling Support Tickets for ${projectDetails?.name} Project`}
          </Typography>
          <Button onClick={() => setAddModalOpen(true)} sx={{ width: { xs: '100%', sm: 'auto' } }}>
            Attach Support Ticket <AddIcon />
          </Button>
        </Grid>
        <AttachSupportTicketsPopup addModalOpen={addModalOpen} setAddModalOpen={setAddModalOpen} />
        <Grid container alignItems="center" mb={3}>
          <Grid
            container
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            spacing={4}
          >
            <Grid item xs={12} md={12} lg={3} sx={{ mb: { xs: '10px', sm: 0 } }}>
              <Box sx={{ height: '60px', width: '100% !important' }}>
                <SelectBox
                  items={[
                    { key: 'All', value: 'All' },
                    ...Object.values(SEVERITY_TYPES).map((severityType) => ({
                      key: severityType,
                      value: severityType,
                    })),
                  ]}
                  id="severity-selector"
                  name="severtiy-selector"
                  textLabel="Severity"
                  onChange={(e) => {
                    initialize();
                    setSeverity(e.target.value);
                  }}
                  value={capitalizeFirstLetter(severityValue)}
                  stringFormat={capitalizeFirstLetter}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={3} sx={{ mb: { xs: '10px', sm: 0 } }}>
              <Box sx={{ height: '60px', width: '100% !important' }}>
                <SelectBox
                  items={[
                    { key: 'All', value: 'All' },
                    ...Object.values(SUPPORT_TICKET_STATUS).map((ticketStatus) => ({
                      key: ticketStatus,
                      value: ticketStatus,
                    })),
                  ]}
                  id="status-selector"
                  name="status-selector"
                  textLabel="Status"
                  onChange={(e) => {
                    initialize();
                    setStatus(e.target.value);
                  }}
                  value={capitalizeFirstLetter(statusValue)}
                  stringFormat={capitalizeFirstLetter}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={3} sx={{ mb: { xs: '10px', sm: 0 } }}>
              <Box sx={{ height: '60px', width: '100% !important' }}>
                <SelectBox
                  items={SUPPORT_TICKET_SORTBY_FIELDS}
                  textLabel="Sort By Field"
                  onChange={(_, key) => {
                    initialize();
                    setPageController({
                      ...pageController,
                      sortByField: key.key.replace('.$', ''),
                    });
                  }}
                  value={pageController.sortByField}
                  renderValue={(valll) =>
                    SUPPORT_TICKET_SORTBY_FIELDS.find((item) => item.key === valll)?.value
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={12} lg={3} sx={{ mb: { xs: '10px', sm: 0 } }}>
              <Box sx={{ height: '60px', width: '100% !important' }}>
                <SelectBox
                  items={[
                    ...Object.values(SORT_TYPES).map((sortType) => ({
                      key: sortType,
                      value: sortType,
                    })),
                  ]}
                  textLabel="Sort By"
                  onChange={(e) => {
                    initialize();
                    setPageController({ ...pageController, sortBy: e.target.value });
                  }}
                  value={pageController.sortBy}
                  stringFormat={toUpperCase}
                  renderValue={toUpperCase}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      {isFirst ? (
        <ShimmerTable row={4} col={8} />
      ) : (
        <InfiniteScroll
          dataLength={supportTickets?.docs?.length ?? 0}
          next={fetchNextPage}
          hasMore={supportTickets?.hasNextPage}
          loader={
            <Box textAlign="center" margin="1.125rem">
              <CircularProgress size={30} />
            </Box>
          }
        >
          <DataGrid
            columns={SUPPORT_TICKET_HANDLING_TABLE_COLUMNS}
            rows={supportTickets?.docs?.map((supportTicket) => ({
              ...supportTicket,
              id: supportTicket?.id,
              project: {
                value: supportTicket?.projectId?.name,
                onClick: (event) => {
                  event.stopPropagation();
                  handleTitleOnClick(event, supportTicket?.id);
                },
              },
              raisedBy: {
                value: supportTicket?.raisedBy?.firstName.concat(
                  ' ',
                  supportTicket?.raisedBy?.lastName
                ),
              },
              title: {
                value: supportTicket?.title,
                onClick: (event) => {
                  event.stopPropagation();
                  handleTitleOnClick(event, supportTicket?.id);
                },
              },
              description: {
                value: supportTicket?.description,
              },
              severity: { severity: supportTicket?.severity },
              createdAt: {
                value: new Date(supportTicket?.createdAt).toLocaleDateString(
                  DATE_LANGUAGE.LANGUAGE,
                  DATE_FORMAT_SLASH
                ),
              },
              updatedAt: {
                value: new Date(supportTicket?.updatedAt).toLocaleDateString(
                  DATE_LANGUAGE.LANGUAGE,
                  DATE_FORMAT_SLASH
                ),
              },
              attachments: {
                value: supportTicket?.attachments,
              },
              dataStatus: {
                value: supportTicket?.acknowledged?.isAcknowledged,
                onClick: (event) => {
                  event.stopPropagation();
                  if (supportTicket?.acknowledged?.isAcknowledged) return;
                  setTicketToResolve(supportTicket);
                  setIsResolvePopupOpen(true);
                },
              },
              loggedTime: {
                value: supportTicket?.loggedTime ? supportTicket?.loggedTime?.toFixed(2) : 0,
              },
              jiraIssueId: {
                value: supportTicket?.jiraIssueId,
              },
              comments: {
                handleCommentOnClick: (event) => {
                  event.stopPropagation();
                  handleCommentOnClick(supportTicket?.id);
                },
              },
            }))}
            page={0}
            totalPages={supportTickets?.hasNextPage ? supportTickets?.totalPages ?? 0 : 0}
            totalDocs={supportTickets?.totalDocs ?? 0}
            limit={
              !supportTickets?.prevPage
                ? pageController.rowsPerPage
                : supportTickets?.docs?.length ?? 0
            }
            loading={isLoading && isFirst !== true}
            rowHeight={supportTickets?.docs?.length ? 52 : 200}
            clickable
          />
        </InfiniteScroll>
      )}
      <CommentSectionPopup
        commentSectionModelOpen={commentSectionModelOpen}
        setCommentSectionModelOpen={setCommentSectionModelOpen}
        ticketId={ticketId}
        setTicketId={setTicketId}
      />
    </Box>
  );
};
//
export default HandlingSupportTicketsTable;
