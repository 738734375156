/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
//
import { signInActions } from 'features/sign-in/slice';

export const initialState = {
  supportTickets: {},
  individualSupportTicket: {},
  individualSupportTicketLoading: false,
  individualSupportTicketProcessing: false,
  allComments: [],
  loading: false,
  ticketStatusLoading: false,
  uploadedAttachments: [],
  relatedProjectsClient: [],
  iTSupportProjects: [],
  managingProjects: [],
  updatedSupportTicket: [],
  commentUpdatedSupportTicket: [],
  commentLoading: false,
  addSupportTicketLoading: false,
  attachmentLoading: false,
  isInitialCommentFetching: false,
  isCommentAdding: false,
  isCommentAttachmentAdding: false,
};
/**
 * Redux slice for the support tickets feature
 */
export const supportTicketSlice = createSlice({
  name: 'feature/support-tickets',
  initialState,
  reducers: {
    setInitialState: (state) => ({
      ...state,
      supportTickets: {
        docs: [],
      },
      individualSupportTicket: {},
      allComments: [],
      uploadedAttachments: [],
      updatedSupportTicket: [],
      commentUpdatedSupportTicket: [],
    }),
    resetSupportTickets: (state) => ({
      ...state,
      supportTickets: {
        docs: [],
      },
      individualSupportTicket: {},
      allComments: [],
      uploadedAttachments: [],
      updatedSupportTicket: [],
      commentUpdatedSupportTicket: [],
    }),
    getSupportTickets: (state) => ({
      ...state,
      loading: true,
    }),
    getSupportTicketsSucceeded: (state, action) => ({
      ...state,
      loading: false,
      supportTickets:
        state.supportTickets?.docs?.length === 0
          ? action?.payload
          : {
              ...action?.payload,
              docs: [...(state.supportTickets?.docs ?? []), ...(action?.payload?.docs ?? [])],
            },
    }),
    getSupportTicketsFailed: (state) => ({
      ...state,
      loading: false,
    }),
    getSupportTicketById: (state) => ({
      ...state,
      individualSupportTicketLoading: true,
    }),
    getSupportTicketByIdSucceeded: (state, action) => ({
      ...state,
      individualSupportTicketLoading: false,
      individualSupportTicket: action?.payload,
    }),
    getSupportTicketByIdFailed: (state) => ({
      ...state,
      individualSupportTicketLoading: false,
    }),
    uploadAttachments: (state) => ({
      ...state,
      attachmentLoading: true,
    }),
    uploadAttachmentsSucceeded: (state, action) => ({
      ...state,
      uploadedAttachments: [...state.uploadedAttachments, ...(action?.payload ?? [])],
      attachmentLoading: false,
    }),
    uploadAttachmentsFailed: (state) => ({
      ...state,
      attachmentLoading: false,
      uploadedAttachments: [],
    }),
    clearAttachments: (state) => ({
      ...state,
      uploadedAttachments: [],
    }),
    setAttachments: (state, action) => ({
      ...state,
      uploadedAttachments: action.payload,
    }),
    addSupportTickets: (state) => ({
      ...state,
      addSupportTicketLoading: true,
    }),
    addSupportTicketsSucceeded: (state, action) => ({
      ...state,
      addSupportTicketLoading: false,
      supportTickets: {
        ...state.supportTickets,
        docs: [action?.payload, ...state.supportTickets.docs],
        totalDocs: state.supportTickets.totalDocs + 1,
      },
      uploadedAttachments: [],
    }),
    addSupportTicketsFailed: (state) => ({
      ...state,
      addSupportTicketLoading: false,
    }),
    getComments: (state) => ({
      ...state,
      commentLoading: true,
    }),
    getCommentsSucceeded: (state, action) => ({
      ...state,
      allComments: action.payload,
      commentLoading: false,
      isInitialCommentFetching: false,
    }),
    getCommentsFailed: (state) => ({
      ...state,
      commentLoading: false,
      isInitialCommentFetching: false,
    }),
    addComments: (state) => ({
      ...state,
      isCommentAdding: true,
    }),
    addCommentsSucceeded: (state, action) => ({
      ...state,
      allComments: action.payload,
      isCommentAdding: false,
    }),
    addCommentsFailed: (state) => ({
      ...state,
      isCommentAdding: false,
    }),
    addCommentsAttachments: (state) => ({
      ...state,
      isCommentAttachmentAdding: true,
    }),
    addCommentsAttachmentsSucceeded: (state, action) => ({
      ...state,
      allComments: action.payload,
      isCommentAttachmentAdding: false,
    }),
    addCommentsAttachmentsFailed: (state) => ({
      ...state,
      isCommentAttachmentAdding: false,
    }),
    clearComment: (state) => {
      state.allComments = [];
    },
    updateIndividualSupportTicket: (state) => ({
      ...state,
      individualSupportTicketProcessing: true,
    }),
    updateIndividualSupportTicketSucceeded: (state, action) => ({
      ...state,
      individualSupportTicketProcessing: false,
      individualSupportTicket: action.payload,
    }),
    updateIndividualSupportTicketFailed: (state) => ({
      ...state,
      individualSupportTicketProcessing: false,
    }),
    updateSeverity: (state) => ({
      ...state,
      ticketStatusLoading: true,
    }),
    updateSeveritySucceeded: (state, action) => ({
      ...state,
      ticketStatusLoading: false,
      supportTickets: {
        ...state.supportTickets,
        docs: state.supportTickets.docs.map((ticket) => {
          if (ticket.id === action.payload.id) {
            return action.payload;
          }
          return ticket;
        }),
      },
    }),
    updateSeverityFailed: (state) => ({
      ...state,
      ticketStatusLoading: false,
    }),
    updateResolvedStatus: (state) => ({
      ...state,
      ticketStatusLoading: true,
    }),
    updateResolvedStatusSucceeded: (state, action) => ({
      ...state,
      ticketStatusLoading: false,
      supportTickets: {
        ...state.supportTickets,
        docs: state.supportTickets.docs.map((ticket) => {
          if (ticket.id === action.payload.id) {
            return action.payload;
          }
          return ticket;
        }),
      },
    }),
    updateResolvedStatusFailed: (state) => ({
      ...state,
      ticketStatusLoading: false,
    }),
    getItSupportProjects: (state) => ({
      ...state,
      loading: true,
    }),
    getItSupportProjectsSucceeded: (state, action) => ({
      ...state,
      iTSupportProjects: action?.payload?.docs,
      loading: false,
    }),
    getItSupportProjectsFailed: (state) => ({
      ...state,
      loading: false,
    }),
    getProjectsOfClient: (state) => ({
      ...state,
      loading: true,
    }),
    getProjectsOfClientSucceeded: (state, action) => ({
      ...state,
      /** Obtain a list of projects from the client that the user belongs to 
        (works if user belongs to mulitple client organizations as well) */
      relatedProjectsClient: action?.payload?.docs?.flatMap((doc) => doc?.projects),
      loading: false,
    }),
    getProjectsOfClientFailed: (state) => ({
      ...state,
      loading: false,
    }),
    getManagingProjects: (state) => ({
      ...state,
      loading: true,
    }),
    getManagingProjectsSucceeded: (state, action) => ({
      ...state,
      managingProjects: action?.payload?.docs,
      loading: false,
    }),
    getManagingProjectsFailed: (state) => ({
      ...state,
      loading: false,
    }),
    setIsInitialCommentFetching: (state, action) => ({
      ...state,
      isInitialCommentFetching: action?.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(signInActions.signIn, () => ({
      ...initialState,
    }));
  },
});
//
export const { actions: supportTicketActions } = supportTicketSlice;
